<template>
  <AuthPasswordResetConfirmation />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AuthPasswordResetConfirmation } from '@bd/components'

export default defineComponent({
  name: 'PasswordResetConfirmation',
  components: { AuthPasswordResetConfirmation },
})
</script>

<style lang="scss" scoped></style>
